import * as _forEach2 from "for-each";
var _forEach = _forEach2;
try {
  if ("default" in _forEach2) _forEach = _forEach2.default;
} catch (e) {}
import * as _availableTypedArrays2 from "available-typed-arrays";
var _availableTypedArrays = _availableTypedArrays2;
try {
  if ("default" in _availableTypedArrays2) _availableTypedArrays = _availableTypedArrays2.default;
} catch (e) {}
import * as _callBind2 from "call-bind";
var _callBind = _callBind2;
try {
  if ("default" in _callBind2) _callBind = _callBind2.default;
} catch (e) {}
import * as _callBound2 from "call-bind/callBound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
import * as _gopd2 from "gopd";
var _gopd = _gopd2;
try {
  if ("default" in _gopd2) _gopd = _gopd2.default;
} catch (e) {}
import * as _shams2 from "has-tostringtag/shams";
var _shams = _shams2;
try {
  if ("default" in _shams2) _shams = _shams2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var forEach = _forEach;
var availableTypedArrays = _availableTypedArrays;
var callBind = _callBind;
var callBound = _callBound;
var gOPD = _gopd;

/** @type {(O: object) => string} */
var $toString = callBound("Object.prototype.toString");
var hasToStringTag = _shams();
var g = typeof globalThis === "undefined" ? _global : globalThis;
var typedArrays = availableTypedArrays();
var $slice = callBound("String.prototype.slice");
var getPrototypeOf = Object.getPrototypeOf; // require('getprototypeof');

/** @type {<T = unknown>(array: readonly T[], value: unknown) => number} */
var $indexOf = callBound("Array.prototype.indexOf", true) || function indexOf(array, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i] === value) {
      return i;
    }
  }
  return -1;
};

/** @typedef {(receiver: import('.').TypedArray) => string | typeof Uint8Array.prototype.slice.call | typeof Uint8Array.prototype.set.call} Getter */
/** @type {{ [k in `\$${import('.').TypedArrayName}`]?: Getter } & { __proto__: null }} */
var cache = {
  __proto__: null
};
if (hasToStringTag && gOPD && getPrototypeOf) {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    if (Symbol.toStringTag in arr) {
      var proto = getPrototypeOf(arr);
      // @ts-expect-error TS won't narrow inside a closure
      var descriptor = gOPD(proto, Symbol.toStringTag);
      if (!descriptor) {
        var superProto = getPrototypeOf(proto);
        // @ts-expect-error TS won't narrow inside a closure
        descriptor = gOPD(superProto, Symbol.toStringTag);
      }
      // @ts-expect-error TODO: fix
      cache["$" + typedArray] = callBind(descriptor.get);
    }
  });
} else {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    var fn = arr.slice || arr.set;
    if (fn) {
      // @ts-expect-error TODO: fix
      cache["$" + typedArray] = callBind(fn);
    }
  });
}

/** @type {(value: object) => false | import('.').TypedArrayName} */
var tryTypedArrays = function tryAllTypedArrays(value) {
  /** @type {ReturnType<typeof tryAllTypedArrays>} */var found = false;
  forEach(
  // eslint-disable-next-line no-extra-parens
  /** @type {Record<`\$${TypedArrayName}`, Getter>} */ /** @type {any} */
  cache, /** @type {(getter: Getter, name: `\$${import('.').TypedArrayName}`) => void} */
  function (getter, typedArray) {
    if (!found) {
      try {
        // @ts-expect-error TODO: fix
        if ("$" + getter(value) === typedArray) {
          found = $slice(typedArray, 1);
        }
      } catch (e) {/**/}
    }
  });
  return found;
};

/** @type {(value: object) => false | import('.').TypedArrayName} */
var trySlices = function tryAllSlices(value) {
  /** @type {ReturnType<typeof tryAllSlices>} */var found = false;
  forEach(
  // eslint-disable-next-line no-extra-parens
  /** @type {Record<`\$${TypedArrayName}`, Getter>} */ /** @type {any} */
  cache, /** @type {(getter: typeof cache, name: `\$${import('.').TypedArrayName}`) => void} */function (getter, name) {
    if (!found) {
      try {
        // @ts-expect-error TODO: fix
        getter(value);
        found = $slice(name, 1);
      } catch (e) {/**/}
    }
  });
  return found;
};

/** @type {import('.')} */
exports = function whichTypedArray(value) {
  if (!value || typeof value !== "object") {
    return false;
  }
  if (!hasToStringTag) {
    /** @type {string} */
    var tag = $slice($toString(value), 8, -1);
    if ($indexOf(typedArrays, tag) > -1) {
      return tag;
    }
    if (tag !== "Object") {
      return false;
    }
    // node < 0.6 hits here on real Typed Arrays
    return trySlices(value);
  }
  if (!gOPD) {
    return null;
  } // unknown engine
  return tryTypedArrays(value);
};
export default exports;